<template>
  <div class="p-2 text-2xl text-center">
    <!-- This example requires Tailwind CSS v2.0+ -->

    <div class="center grid">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
        Unidad seleccionada: {{ title }} 
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
        Modelo: {{ type }}
      </vs-col>
    </vs-row>
    </div>
    <div class="relative mt-2">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300" />
      </div>
      <div class="relative flex justify-center"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TituloApartamento",
  props: ["title","type"],
  data() {
    return {};
  },
  computed: {
    apartamentNumber() {
      return localStorage.apartamentNumber;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>