<template>
  <div>
    <vs-row class="flex flex-wrap">
      <vs-col class="sm:w-full md:w-full lg:w-2/3 mb-4">
        <swiper :options="swiperOption">
          <swiper-slide
            :key="index"
            v-for="(img, index) in property.project_unit_type
              .project_unit_type_images"
          >
            <img class="slider" :src="img.image_url" alt="banner" />
          </swiper-slide>
          <div
            class="swiper-pagination swiper-pagination-white"
            slot="pagination"
          ></div>
          <div
            class="swiper-button-prev swiper-button-white"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-white"
            slot="button-next"
          ></div>
        </swiper>
      </vs-col>
      <vs-col class="sm:w-full md:w-1/4 lg:w-1/4 mb-4 px-2">
        <div class="bg-white">
          <div>
            <vs-list-item
              icon="tag"
              :title="`Unidad: ${property.unit_number}`"
            ></vs-list-item>
            <vs-list-item
              icon="layers"
              :title="`Nivel: ${property.level}`"
            ></vs-list-item>
            <vs-list-item
              icon="texture"
              :title="`Área: ${property.construction_area} m2`"
            ></vs-list-item>
            <vs-list-item
              icon="local_hotel"
              :title="`Habitaciones: ${property.rooms}`"
            ></vs-list-item>
            <vs-list-item
              icon="bathtub"
              :title="`Baños: ${property.bathrooms}`"
            ></vs-list-item>
            <vs-list-item
              icon="time_to_leave"
              :title="`Parqueos Asignados: ${parkingsProperty}`"
            ></vs-list-item>
            <vs-list-item
              icon="time_to_leave"
              :title="`Bodegas Asignadas: ${warehouseProperty}`"
            ></vs-list-item>
            <vs-list-item
              v-show="property.balcony_area != null"
              icon="tag"
              :title="`Area balcón: ${property.balcony_area}`"
            ></vs-list-item>
            <vs-list-item
              v-show="property.garden_area != null"
              icon="tag"
              :title="`Area jardín: ${property.garden_area}`"
            ></vs-list-item>
            <!-- attributes -->
            <vs-list-item
              v-for="property in propertyAttributes"
              :key="property.id"
              :icon="property.property_icon"
              :title="`${property.property_attribute}: ${property.value}`"
            ></vs-list-item>
            <vs-list-item
              icon="monetization_on"
              :title="`Precio: ${priceProperty}`"
            ></vs-list-item>
          </div>
        </div>
        <div>
          <vs-button
            color="primary"
            class="w-full mt-4 h-16 text-xl shadow-sm"
            @click="linkToQuotes()"
            >Generar Cotización</vs-button
          >
        </div>
      </vs-col>
    </vs-row>
    <p class="text-2xl p-2">Más ventajas en {{ project.name }}</p>

    <div class="p-2 mt-4">
      <ul
        role="list"
        class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-4 lg:grid-cols-3 xl:gap-x-4"
      >
        <li v-for="i in getGallery" :key="i.id" class="relative">
          <div
            class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 group block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden"
          >
            <img
              :src="i.img_url"
              alt=""
              class="object-cover pointer-events-none"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import { mapState } from "vuex";
import { api } from "../../projects/services";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import currencies from "../../../helpers/currencies";

export default {
  data() {
    return {
      project: null,
      swiperOption: {
        spaceBetween: 30,
        effect: "fade",
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  name: "ListadoDetalle",
  props: ["property"],
  components: {
    swiper,
    swiperSlide
  },
  methods: {
    dispatchProperty(propertyId) {
      this.$store.dispatch("property/setSelectedPropertyId", { propertyId });
    },
    linkToQuotes() {
      const propertyId = this.property.id;
      this.dispatchProperty(propertyId);
      this.$router.push({
        path: "/generar-cotizacion/",
        params: { id: this.property.id }
      });
    },
    getFormatCurrency(value) {
      const currencySymbol = this.$store.getters["project/currencySymbol"];

      return currencies.currencyFormatter({
        value,
        currency: currencySymbol
      });
    },
    getPropertiesAssignedSum(propertiesAssigned, propertyTypeId = "PARKING") {
      if (!propertiesAssigned.length) return 0;

      const props = this.getPropertiesAssigned(
        propertiesAssigned,
        propertyTypeId
      );

      if (!props.length) return 0;

      const sum = props
        .map(i => i.property.price)
        .reduce((previous, current) => previous + current);

      return sum;
    },
    getPropertiesAssignedQty(propertiesAssigned, propertyTypeId = "PARKING") {
      if (!propertiesAssigned.length) return 0;

      const props = this.getPropertiesAssigned(
        propertiesAssigned,
        propertyTypeId
      );

      if (!props.length) return 0;

      return props.length;
    },
    getPropertiesAssigned(properties, propertyTypeId = "PARKING") {
      return properties.filter(
        i => i.property.property_type_id === propertyTypeId
      );
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    getGallery() {
      if (!this.project.project_images.length) return null;
      const galleryImgs = this.project.project_images.filter(img => {
        if (img.type === "FINISHES") {
          return img;
        }
      });
      return galleryImgs;
    },
    propertyAttributes() {
      if (!this.property.property_attribute_values.length) return null;
      const attributes = this.property.property_attribute_values;
      const newAttributes = attributes.map(item => {
        const attr = {};
        attr.id = item.id;
        if (item.value === "true") {
          attr.value = "Si";
        } else {
          attr.value = "No";
        }
        if (item.property_attribute === "BALCONY") {
          attr.property_attribute = "Balcón";
          attr.property_icon = "terrain";
        }
        if (item.property_attribute === "KITCHEN_FURNITURE") {
          attr.property_attribute = "Muebles de cocina";
          attr.property_icon = "view_column";
        }
        if (item.property_attribute === "CLOSET") {
          attr.property_attribute = "Armario";
          attr.property_icon = "view_carousel";
        }
        if (item.property_attribute === "LAUNDRY") {
          attr.property_attribute = "Lavanderia";
          attr.property_icon = "local_laundry_service";
        }
        if (item.property_attribute === "HOME_APPLIANCES") {
          attr.property_attribute = "Electrodomésticos";
          attr.property_icon = "kitchen";
        }
        if (item.property_attribute === "GARDEN") {
          attr.property_attribute = "Jardin";
          attr.property_icon = "local_florist";
        }
        if (item.property_attribute === "TOWER_NUMBER") {
          attr.property_attribute = "Torre";
          attr.property_icon = "local_florist";
          attr.value = item.value;
        }
        if (item.property_attribute === "VIEW_STREET") {
          attr.property_attribute = "Vista";
          attr.property_icon = "local_florist";
          attr.value = item.value;
        }
        return attr;
      });
      return newAttributes;
    },
    priceProperty() {
      return this.getFormatCurrency(
        this.property.price +
          this.getPropertiesAssignedSum(
            this.property.assigned_properties,
            "PARKING"
          ) +
          this.getPropertiesAssignedSum(
            this.property.assigned_properties,
            "WAREHOUSE"
          )
      );
    },
    parkingsProperty() {
      return this.getPropertiesAssignedQty(
        this.property.assigned_properties,
        "PARKING"
      );
    },
    warehouseProperty() {
      return this.getPropertiesAssignedQty(
        this.property.assigned_properties,
        "WAREHOUSE"
      );
    }
  },
  async mounted() {
    const projectId = this.$store.getters["project/currentProject"];
    this.project = await api.get(projectId);
  }
};
</script>

<style lang="scss">
.vs-list--title {
  font-weight: 400 !important;
  font-size: 16px;
}
</style>
