<template>
  <div>
    <div>
      <TituloComponent :title="property.unit_number" :type="property.project_unit_type.type" />
      <br />
      <ListadoElementos :property="property" />
    </div>
  </div>
</template>

<script>
import TituloComponent from "../components/TitleComponent";
import ListadoElementos from "../components/ListadoElementosComponent";
import { getPropertyByPk } from "../services/property.api";
export default {
  data() {
    return {
      property: {}
    };
  },
  components: {
    TituloComponent,
    ListadoElementos
  },
  mounted() {
    this.getProperty(this.$router.history.current.params.Id);
  },
  methods: {
    async getProperty(id) {
      const response = await getPropertyByPk(id);
      if (response && response.data) {
        this.property = response.data.property_by_pk;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
